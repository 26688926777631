import * as React from 'react'
import { CDN_ENDPOINT, ASSETS_ENDPOINT } from '@env'
import { ApplicationLayout } from '../layouts/ApplicationLayout'
import {
	MainLandingPage,
	LandingStore,
	LandingApolloService,
} from '@sejinmind/piip-landing-modules'

import { Provider } from 'mobx-react'
import { serverLanguageFilter, withApolloContext, LangSeo, getCookie } from '@sejinmind/piip-utils'

import axios from 'axios'

const IS_POPUP_CLOSED = 'cookie_popup_closed'

class PiIpMain extends React.Component {
	static async getInitialProps({ pathname, query, apolloClient, ...ctx }) {
		const { language = 'en' } = query

		const jsonRes = await axios.get(ASSETS_ENDPOINT + `/static/jsons/main.json`)
		const { data: jsonData } = jsonRes
		const labels = jsonData[language]

		const isPopupClosed = await getCookie(IS_POPUP_CLOSED, ctx?.req)

		try {
			const filterLanguage = serverLanguageFilter(language)

			const articlesFilter = {
				_translations: { lang: filterLanguage },
			}
			const articlesPageable = {
				offset: 0,
				limit: 5,
				sort: { property: 'translations.publishedAt', direction: 'DESC' },
			}
			const translationFilter = { lang: filterLanguage }

			const service = LandingApolloService.createInstance(apolloClient)

			const {
				errors,
				loading,
				data: {
					searchArticles: { content: articles },
				},
			} = await service.fetchArticles(articlesFilter, articlesPageable, translationFilter)

			if (errors) throw errors

			return { pathname, query, labels, articles, language, isPopupClosed }
		} catch (errors) {
			return { pathname, query, labels, language, isPopupClosed }
		}
	}

	constructor(props) {
		super(props)

		const { apolloClient } = props
		const options = {
			[LandingStore.type.LANDING_MODEL]: {
				service: LandingApolloService.createInstance(apolloClient),
			},
		}

		this.landingStore = LandingStore.createInstance(options)
	}

	render() {
		const { query, statusCode, language, isPopupClosed } = this.props

		const isPageError = statusCode >= 400 || statusCode >= 400

		return (
			<React.Fragment>
				<LangSeo language={language} />
				<Provider {...this.landingStore.getStores()}>
					<ApplicationLayout backgroundColor="#fff" language={query?.language || 'en'}>
						<MainLandingPage {...this.props} cdnEndPoint={CDN_ENDPOINT} />
					</ApplicationLayout>
				</Provider>
			</React.Fragment>
		)
	}
}

export default withApolloContext(PiIpMain)
